import { NestedCutList } from "features/Quote/utils";
import { LocaleOptions, LocaleString } from "common/services";
import { SelectedItem, Item, PlantOption, NormalizedObjects, ParsedItem, ItemPlant } from "./types";
import { MillWeightInit } from "./constants";

export const formatDate = (date: Date) => date.toLocaleDateString(LocaleString, LocaleOptions);

export const parseIso8601Date = (date: string) =>
  date.split("-").map((datepart) => Number(datepart));

export const parseUsDate = (date: string) => {
  const [month, day, year] = date.split("/").map((datepart) => Number(datepart));
  return [year, month, day];
};
export const toDate = (date: string) => {
  let year: number, month: number, day: number;
  if (date.includes("-")) {
    [year, month, day] = parseIso8601Date(date);
  } else {
    [year, month, day] = parseUsDate(date);
  }
  return new Date(year, month - 1, day);
};

export const apiDateToUsDate = (date: string | null) => {
  if (!date) return date;
  return formatDate(toDate(date));
};
export const formatNestedLength = (value: number) => {
  if (!value) {
    return "";
  }
  let valueStr = value.toString();
  if (valueStr.includes(".")) {
    valueStr = `${valueStr.replace(".", "'")}"`;
  } else {
    valueStr = `${valueStr}'00"`;
  }
  return valueStr;
};

export const formatSapDate = (date: Date) =>
  date
    .toLocaleDateString("es-BR", {
      //brazil dates are dd/mm/yyyy
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .toString()
    //remove slashes because SAP only take the numeric val like 04042021 as a string
    .replaceAll("/", "");

export const formatItemDescription = (item: Item | ParsedItem) =>
  `${item.shape}${item.size} ${item.grade} ${formatNestedLength(item.nested_length)}`;

const daysBetweenDates = (startDate: Date, endDate: Date) => {
  const millisecondsPerDay = 1000 * 3600 * 24;
  var millisecondDifference = startDate.getTime() - endDate.getTime();
  return Math.ceil(millisecondDifference / millisecondsPerDay);
};
export const getDaysUntilRollingCloses = (
  quoteDate: Date,
  rollingStartDate: Date,
  stopOpenOrderDays: number = 8,
  castDateDays: number = 8,
) => {
  const totalDaysToSubtract = castDateDays + stopOpenOrderDays;
  const rollingCloseDate = new Date(rollingStartDate);
  rollingCloseDate.setDate(rollingStartDate.getDate() - totalDaysToSubtract);
  return daysBetweenDates(rollingCloseDate, quoteDate);
};

export const getItemSelectedQty = (
  item: Item,
  selectedOptionIds: SelectedItem[],
  options: NormalizedObjects<PlantOption>,
) => {
  const itemOptionIds = selectedOptionIds
    .filter((id) => id.itemUid === item.item_uid)
    .map((id) => id.optionUid);
  return itemOptionIds.reduce((accum, id) => accum + options[id].orderQuantity, 0);
};

export const getTotalOptionWeight = (option: PlantOption) =>
  (option.standardBundleWeight / option.standard_piece_count) * option.orderQuantity;

export const sumAutoSelectedMillWeights = (
  selectedIds: SelectedItem[],
  options?: NormalizedObjects<PlantOption>,
) => {
  const weights = { ...MillWeightInit };
  if (options) {
    const optionIds = selectedIds.map((ids) => ids.optionUid);
    optionIds.forEach((id) => {
      const option = options[id];
      weights[option.plant] += getTotalOptionWeight(option);
    });
  }
  return weights;
};

export const calculateDropPercent = (item: Item | null) => {
  let percentCount = 0;
  let cutListLen = item?.cut_list?.length ?? 0;

  if (item && item.cut_list && cutListLen > 0) {
    for (const cutListItem of item.cut_list) {
      percentCount = percentCount + cutListItem.waste_pct;
    }
    return percentCount / cutListLen;
  }
  return 0;
};

export const combineCutListPieces = (NestedCutList: NestedCutList[]) => {
  let pieceCount = 0;

  const millOptionsGroupedByDate = Array.from(
    NestedCutList.reduce(function (map, item) {
      pieceCount = pieceCount + 1;

      if (item["pieces"].length === 1) {
        if (!map.has(item["pieces"][0].length)) {
          pieceCount = 1;
        }
      } else {
        if (!map.has(item["pieces"][0].length) && !map.has(item["pieces"][1].length)) {
          pieceCount = 1;
        }
      }

      const values = {
        waste_pct: item.waste_pct,
        pieces: item["pieces"],
        piecesCount: pieceCount,
        rowSpan: item["pieces"].length,
        millMark: item.mill_mark,
        sequence: item.sequence,
      };
      if (item["pieces"].length === 1) {
        map.set(item["pieces"][0].length, values);
      } else {
        map.set(item["pieces"][0].length + item["pieces"][1].length, values);
      }

      return map;
    }, new Map()).values(),
  );
  return millOptionsGroupedByDate;
};

export const formatLength = (length: number) => {
  const inchMarkPresent = length.toString().endsWith('"');
  const footMarkPresent = length.toString().endsWith("'");

  return inchMarkPresent || footMarkPresent ? length : `${length}"`;
};

export const calculateFraction = (value: number) => {
  //Greatest common denominator
  const gcd: any = function (a: number, b: number) {
    if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

    return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  };
  const fraction = value;
  const len = fraction.toString().length - 2;

  let denominator = Math.pow(10, len);
  let numerator = fraction * denominator;

  const divisor = gcd(numerator, denominator);

  numerator /= divisor;
  denominator /= divisor;
  if (numerator === denominator) {
    return 1;
  } else if (numerator === 0) {
    return 0;
  }
  return Math.floor(numerator) + "/" + Math.floor(denominator);
};

export const quantityFilter = (option: PlantOption) => {
  let difference: number;

  if (option.pieces === option.orderQuantity) {
    return "2";
  } else if (option.pieces > option.orderQuantity) {
    difference = option.pieces - option.orderQuantity;
    return "1";
  }

  difference = option.orderQuantity - option.pieces;
  if (difference === 1) {
    return "3";
  } else if (difference > 1) {
    return "4";
  }
};

export const availabilityFilter = (option: PlantOption, requestDate: string) => {
  const daysUntilRollingCloses = option.date_ready_week
    ? getDaysUntilRollingCloses(new Date(), toDate(option.date_ready_week), 0)
    : undefined;

  if (toDate(option.date_ready_week) <= toDate(requestDate)) {
    return "1"; //On Time
  } else if (daysUntilRollingCloses !== undefined && daysUntilRollingCloses <= 7) {
    return "2";
  } else if (daysUntilRollingCloses !== undefined && daysUntilRollingCloses <= 14) {
    return "3";
  } else if (
    (daysUntilRollingCloses !== undefined && daysUntilRollingCloses <= 21) ||
    option.selection.toLowerCase().includes("rolling past request")
  ) {
    return "4";
  } else if (daysUntilRollingCloses === undefined) {
    console.info("Days until rolling is undefined");
    return "4";
  }
  return "";
};

export const filterOptions = (
  item: Item,
  options: NormalizedObjects<PlantOption>,
  itemPlants: NormalizedObjects<ItemPlant>,
  filterQuantity: string,
  filterAvailability: string,
  filterPlant: string,
  requestDate: string,
) => {
  const plantOption: string[] = [];
  let newItem: Item;

  item.options.map((plantId) =>
    itemPlants[plantId].options.forEach((optionId) => {
      if (
        (plantId.substr(0, 4) === filterPlant || filterPlant === "") &&
        ((filterQuantity === "" && filterAvailability === "") ||
          (filterQuantity !== "" &&
            filterAvailability === "" &&
            quantityFilter(options[optionId]) === filterQuantity) ||
          (filterAvailability !== "" &&
            filterQuantity === "" &&
            availabilityFilter(options[optionId], requestDate) === filterAvailability) ||
          (filterQuantity !== "" &&
            filterAvailability !== "" &&
            availabilityFilter(options[optionId], requestDate) === filterAvailability &&
            quantityFilter(options[optionId]) === filterQuantity))
      ) {
        plantOption.push(options[optionId].toString());
      }
    }),
  );
  newItem = {
    ...item,
    options: plantOption,
  };
  return newItem;
};
export const getClosestPlantId = (item: Item, itemPlants: NormalizedObjects<ItemPlant>) =>
  Number(
    item.options
      .filter((plantId) => itemPlants[plantId].options.length > 0)
      .map((plantId) => ({
        id: itemPlants[plantId].plantId,
        distance: itemPlants[plantId].distance,
      }))
      .reduce((prev, current) => (prev.distance <= current.distance ? prev : current)).id,
  );
