import { AppEndPoints, MillInfo } from "./Types";
import { getEndpoints } from "./Util";
export const EndpointMapping = new Map<string, AppEndPoints>([
  [
    "localhost",
    {
      // api: "https://sales-quick-quote-api.dev.gerdaugln.tech",
      api: "http://localhost:8000",
      egerdauBaseUrl: "https://dev.egerdau.com",
      egerdauAuth: "https://dev.egerdau.com/authorizationserver/oauth/authorize",
      egerdauToken: "https://dev.egerdau.com/authorizationserver/oauth/token",
      egerdauCustomer: "https://dev.egerdau.com/rest/v2/egerdauNA/users/current",
      egerdauCallback: "https://dev-sales-quick-quote-web.gerdaugln.tech",
      muleSoftPricing: "https://dev-gln-products.cloudhub.io/api/simulate-price",
    },
  ],
  [
    "dev-sales-quick-quote-web.gerdaugln.tech",
    {
      api: "https://sales-quick-quote-api.dev.gerdaugln.tech",
      egerdauBaseUrl: "https://dev.egerdau.com",
      egerdauAuth: "https://dev.egerdau.com/authorizationserver/oauth/authorize",
      egerdauToken: "https://dev.egerdau.com/authorizationserver/oauth/token",
      egerdauCustomer: "https://dev.egerdau.com/rest/v2/egerdauNA/users/current",
      egerdauCallback: "https://dev-sales-quick-quote-web.gerdaugln.tech",
      muleSoftPricing: "https://dev-gln-products.cloudhub.io/api/simulate-price",
    },
  ],
  [
    "qa-sales-quick-quote-web.gerdaugln.tech",
    {
      api: "https://sales-quick-quote-api.qa.gerdaugln.tech",
      egerdauBaseUrl: "https://qa.egerdau.com",
      egerdauAuth: "https://qa.egerdau.com/authorizationserver/oauth/authorize",
      egerdauToken: "https://qa.egerdau.com/authorizationserver/oauth/token",
      egerdauCustomer: "https://qa.egerdau.com/rest/v2/egerdauNA/users/current",
      egerdauCallback: "https://qa-sales-quick-quote-web.gerdaugln.tech",
      muleSoftPricing: "https://qa-gln-products.cloudhub.io/api/simulate-price",
    },
  ],
  [
    "pre-prod-sales-quick-quote-web.gerdaugln.tech",
    {
      api: "https://sales-quick-quote-api.pre-prod.gerdaugln.tech",
      egerdauBaseUrl: "https://qa.egerdau.com",
      egerdauAuth: "https://qa.egerdau.com/authorizationserver/oauth/authorize",
      egerdauToken: "https://qa.egerdau.com/authorizationserver/oauth/token",
      egerdauCustomer: "https://qa.egerdau.com/rest/v2/egerdauNA/users/current",
      egerdauCallback: "https://qa-sales-quick-quote-web.gerdaugln.tech",
      muleSoftPricing: "https://qa-gln-products.cloudhub.io/api/simulate-price",
    },
  ],
  [
    "quickquote.egerdau.com",
    {
      api: "https://sales-quick-quote-api.gerdaugln.tech",
      egerdauBaseUrl: "https://egerdau.com",
      egerdauAuth: "https://egerdau.com/authorizationserver/oauth/authorize",
      egerdauToken: "https://egerdau.com/authorizationserver/oauth/token",
      egerdauCustomer: "https://egerdau.com/rest/v2/egerdauNA/users/current",
      egerdauCallback: "https://quickquote.egerdau.com",
      muleSoftPricing: "https://gln-products.cloudhub.io/api/simulate-price",
    },
  ],
]);
export const LocaleString = "en-US";
export const UsDollars = Intl.NumberFormat(LocaleString, {
  style: "currency",
  currency: "USD",
});
export const LocaleOptions: Intl.DateTimeFormatOptions = {
  month: "2-digit",
  day: "2-digit",
  year: "numeric",
};
export const {
  api: ApiUrl,
  egerdauBaseUrl: EgerdauUrl,
  egerdauToken: AccessTokenUrl,
  egerdauCustomer: CustomerProfileUrl,
  egerdauAuth: AuthUrl,
  egerdauCallback: CallbackUrl,
  muleSoftPricing: MulePricingApiUrl,
} = getEndpoints(EndpointMapping);

export const MillMap = new Map<number, MillInfo>([
  [1323, { name: "Cartersville", cityState: "Cartersville, GA" }],
  [1327, { name: "Midlothian", cityState: "Midlothian, TX" }],
  [1330, { name: "Petersburg", cityState: "Petersburg, VA" }],
]);

export const CustomerKey = "customer";
export const TokenKey = "token";
export const EGerdauCookie = "qqt_param";
export const CodeParamKey = "code";
export const ClientId = "quick_quote";
export const ClientSecret = "P9Ysz!R6";
export const TemplateDownloadUrl = `${ApiUrl}/file/download_gerdau_template`;
export const HanaSoldToUrl = `${ApiUrl}/${process.env.REACT_APP_SOLDTO}`;
