import clsx from "clsx";
import { IconCheckBox, IconColors, SvgIcon } from "common/components";
import React, { useState } from "react";
import {
  apiDateToUsDate,
  availabilityFilter,
  PlantOption,
  quantityFilter,
  SelectedOption,
} from "../utils";
import { QuantityNotification, DateNotification } from ".";
import styles from "./ItemMillOptionRow.module.scss";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { trackQuoteItemSelection } from "common/tagManagerService";

interface ItemMillOptionRowProps {
  option: PlantOption;
  pieces: number;
  defaultChecked?: boolean;
  requestDate: string;
  filterAvailability: string;
  filterQuantity: string;
  onSelectionChange?: (option: SelectedOption) => void;
}
export const ItemMillOptionRow: React.FunctionComponent<ItemMillOptionRowProps> = ({
  option,
  defaultChecked = false,
  pieces,
  requestDate,
  filterAvailability,
  filterQuantity,
  onSelectionChange = () => {},
}) => {
  const [checked, setChecked] = useState<boolean>(defaultChecked);
  const { orderQuantity, parentItemId, uid } = option;
  const onRowClick = () => {
    const isSelected = !checked;
    setChecked(isSelected);
    trackQuoteItemSelection(isSelected, option);
    onSelectionChange({ itemUid: parentItemId, optionUid: uid, orderQuantity, isSelected });
  };
  const showQuantityFilter = quantityFilter(option) === filterQuantity;
  const showAvailabilityFilter = availabilityFilter(option, requestDate) === filterAvailability;
  const hideRow =
    (!showQuantityFilter && filterQuantity !== "") ||
    (!showAvailabilityFilter && filterAvailability !== "");

  return (
    <>
      {!hideRow && (
        <tr
          className={clsx(styles.container, "font-size-12 bg-white")}
          onClick={onRowClick}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              onRowClick();
            }
          }}
          tabIndex={0}
        >
          <td>
            <div className="d-flex align-items-center">
              <IconCheckBox checked={checked} className="mr-6" />
              {`${option.orderQuantity} PC`}
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center">
              <span className="mr-6">{`${option.standard_piece_count} PC/BDL`}</span>
              <QuantityNotification option={option} />
            </div>
          </td>
          <td>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={"tt-" + option.uid}>
                  Processing: {option.processing_days} days, Transportation:{" "}
                  {option.transportation_days} days
                </Tooltip>
              }
            >
              <span className="d-inline-flex align-items-center">
                <SvgIcon
                  name="info-alert"
                  attributes={{ width: "10px", height: "10px", fill: IconColors.PRIMARY }}
                  className="mr-3"
                />
                <span className="mr-6">{apiDateToUsDate(option.date_ready_week)}</span>
              </span>
            </OverlayTrigger>
            <DateNotification option={option} requestDate={requestDate} />
          </td>
        </tr>
      )}
    </>
  );
};
