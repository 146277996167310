import React, { useState } from "react";
import styles from "./Summary.module.scss";
import { QQSection } from "common/components";
import clsx from "clsx";
import {
  apiDateToUsDate,
  formatDate,
  formatNestedLength,
  QuoteDetails,
  SelectedItem,
} from "../QuoteResults/utils";
import { getSelectedCutList, getSelectedOptions } from "./helpers";
import { DateNotification, QuantityNotification } from "../QuoteResults/components";
import { SummaryLinkSection } from "./SummaryLinkSection";
import { CutListTableSummary } from "../QuoteResults/components/SummaryCutListTable";

export type SummaryProps = {
  quoteId: number | null;
  uid: string;
  requestDate: string;
  file: File | null;
  selectedItems: SelectedItem[];
  quoteDetails: QuoteDetails | null;
  isNesting: boolean;
};

export const Summary: React.FunctionComponent<SummaryProps> = ({
  quoteId,
  uid,
  requestDate,
  file,
  selectedItems = [],
  quoteDetails,
  isNesting,
}) => {
  const options = getSelectedOptions(selectedItems, quoteDetails);
  const cutList = isNesting ? getSelectedCutList(selectedItems, quoteDetails) : undefined;
  const [viewCutList, setViewCutList] = useState(false);

  return (
    <>
      <SummaryLinkSection uid={uid} />
      <QQSection className="p-30">
        <div className={clsx(styles.heading, "d-flex align-items-center mb-6")}>
          <h6 className="text-secondary font-weight-bold font-size-16 m-0 ml-5 flex-fill">
            QQT ID: {quoteId} {"  "}
            <div
              className="btn-group"
              role="group"
              aria-label="Quote and CutList Buttons"
              hidden={!isNesting}
            >
              <button
                id="quoteBtn"
                type="button"
                className={clsx(!viewCutList ? "active" : "", "btn btn-outline-tertiary btn-xs")}
                data-bs-toggle="button"
                aria-pressed={viewCutList === false}
                onClick={() => setViewCutList(false)}
              >
                {" "}
                Quote
              </button>
              <button
                id="cutListBtn"
                type="button"
                className={clsx(viewCutList ? "active" : "", "btn btn-outline-tertiary btn-xs")}
                data-bs-toggle="button"
                aria-pressed={viewCutList === true}
                onClick={() => setViewCutList(true)}
              >
                CutList
              </button>
            </div>
          </h6>
          <p className="font-size-12 text-dark m-0 flex-fill text-center">
            <span className="font-weight-bold">Creation Date:</span> {formatDate(new Date())}
          </p>
          <p className="font-size-12 text-dark m-0 flex-fill text-center">
            <span className="font-weight-bold">Requested Date:</span> {requestDate}
          </p>
          <p className="font-size-12 text-dark m-0 flex-fill text-center">
            <span className="font-weight-bold">File:</span> {file?.name}
          </p>
        </div>

        {viewCutList &&
          cutList &&
          cutList.map((item, index) => (
            <CutListTableSummary item={item} quantitySelected={options[index].orderQuantity} />
          ))}
        {!viewCutList && (
          <table className="table table-bordered font-size-12 text-light">
            <tbody>
              {options.map((option, index, array) => (
                <React.Fragment key={index}>
                  {index === 0 && (
                    <tr className="bg-secondary text-white">
                      <th>Shape</th>
                      <th>Size</th>
                      <th>Grade</th>
                      <th>Length</th>
                      <th>Requested</th>
                      <th>Selected</th>
                      <th>Pieces/Bundle</th>
                      <th>Ready Week</th>
                      <th>Plant</th>
                    </tr>
                  )}
                  <tr>
                    <td>{option.shape}</td>
                    <td>{option.size}</td>
                    <td>{option.grade}</td>
                    <td>{formatNestedLength(option.fulfilled_length)}</td>
                    <td>{`${option.pieces} PC`}</td>
                    <td>
                      <span className="d-inline-flex align-items-center">
                        <span className="mr-10">{`${option.orderQuantity} PC`}</span>
                        <QuantityNotification option={option} />
                      </span>
                    </td>
                    <td>{`${option.standard_piece_count} PC/BDL`}</td>
                    <td>
                      <span className="d-inline-flex align-items-center">
                        <span className="mr-10">{apiDateToUsDate(option.date_ready_week)}</span>
                        <DateNotification option={option} requestDate={requestDate} />
                      </span>
                    </td>
                    <td>{option.plantName.toUpperCase()}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
        <p className={clsx(styles.footer, "font-size-10")}>
          Please note we quote all material as price in effect at the time of shipment. Stock is
          subject to availability and prior sale. Rollings may change/close without prior notice.
        </p>
      </QQSection>
      <SummaryLinkSection uid={uid} />
    </>
  );
};
