import React from "react";
import { pluralize } from "common/services";
import { toDate, getDaysUntilRollingCloses, PlantOption } from "../utils";
import { Notification, NotifyType } from "common/components";

interface DateNotificationProps {
  option: PlantOption;
  requestDate: string;
}

export const DateNotification: React.FunctionComponent<DateNotificationProps> = ({
  option,
  requestDate,
}) => {
  const daysUntilRollingCloses = option.start_date
    ? getDaysUntilRollingCloses(new Date(), toDate(option.start_date), 0)
    : undefined;

  if (option.selection.toLowerCase().includes("rolling past request")) {
    return <Notification type={NotifyType.Error} text="Past Requested Date" />;
  } else {
    if (daysUntilRollingCloses !== undefined && daysUntilRollingCloses <= 8) {
      return (
        <Notification
          type={NotifyType.Error}
          text={`Closes ${
            daysUntilRollingCloses === 0 ? "Today" : pluralize(daysUntilRollingCloses, "day")
          }`}
        />
      );
    }

    if (toDate(option.date_ready_week) <= toDate(requestDate)) {
      return <Notification type={NotifyType.Success} text="On Time" />;
    }
    return <Notification type={NotifyType.Error} text="Past Requested Date" />;
  }
};
