import React, { useState } from "react";
import { IconColors, QQLink, SvgIcon } from "common/components";
import { Item, QuoteDetails, SelectedItem, SelectedOption } from "../utils";
import { MillOptionsSection, ResultsHeaderToggle } from ".";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import clsx from "clsx";
import { trackQuoteFilter } from "common/tagManagerService";

interface ResultsSectionProps {
  textHeading: string;
  itemIds: string[];
  details: QuoteDetails;
  iconColor: IconColors;
  requestDate: string;
  itemsSelected: SelectedItem[];
  shipTo: string;
  shippingMethod: string;
  onSelectionChange?: (option: SelectedOption) => void;
  onCutListClick?: (item: Item, quantitySelected: number) => void;
}

export const ResultsSection: React.FunctionComponent<ResultsSectionProps> = ({
  textHeading,
  details,
  itemIds,
  iconColor,
  requestDate,
  itemsSelected,
  shipTo,
  shippingMethod,
  onSelectionChange = () => {},
  onCutListClick = () => {},
}) => {
  const {
    entities: { items, itemPlants, plantOptions },
  } = details;
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const onToggleClick = () => setShowDetail((prev) => !prev);
  const isNeedsReview = textHeading === "Needs Review";

  const [selectedPlant, setSelectedPlant] = useState<string>("");
  const [filterAvailability, setFilteredAvailability] = useState<string>("");
  const [filterQuantity, setFilteredQuantity] = useState<string>("");
  return (
    <div className="p-28">
      <ResultsHeaderToggle
        toggle={showDetail}
        onClick={onToggleClick}
        text={textHeading}
        resultCount={itemIds.length}
      />
      <div hidden={!isNeedsReview || !showDetail}>
        <span className="p-5" hidden={!isNeedsReview}>
          Filter By:{" "}
        </span>
        <Dropdown as={ButtonGroup} className="btn-group-xs mr-2">
          <Dropdown.Toggle id="dropdown-plant" className="btn-tertiary">
            <SvgIcon
              name={selectedPlant === "" ? "filterOutline" : "filterSolid"}
              attributes={{
                width: "12px",
                height: "12px",
                fill: IconColors.WHITE,
              }}
            />{" "}
            Plant
          </Dropdown.Toggle>
          <Dropdown.Menu className="btn btn-group-xs">
            <Dropdown.Header>Show Only</Dropdown.Header>
            <Dropdown.Item
              eventKey="1327"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setSelectedPlant("1327");
                trackQuoteFilter("PLANT", "Midlothian");
              }}
              active={selectedPlant.includes("1327")}
            >
              Midlothian
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1323"
              as="button"
              className="btn btn-outline-tertiary"
              hidden={false}
              onSelect={() => {
                setSelectedPlant("1323");
                trackQuoteFilter("PLANT", "Cartersville");
              }}
              active={selectedPlant.includes("1323")}
            >
              Cartersville
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1330"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setSelectedPlant("1330");
                trackQuoteFilter("PLANT", "Petersburg");
              }}
              active={selectedPlant.includes("1330")}
            >
              Petersburg
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown as={ButtonGroup} className="btn-group-xs mr-2">
          <Dropdown.Toggle id="dropdown-quantity" className="btn-tertiary" disabled={false}>
            <SvgIcon
              name={filterQuantity === "" ? "filterOutline" : "filterSolid"}
              attributes={{
                width: "12px",
                height: "12px",
                fill: IconColors.WHITE,
              }}
            />{" "}
            Quantity
          </Dropdown.Toggle>
          <Dropdown.Menu className="btn btn-group-xs">
            <Dropdown.Header>Show Only</Dropdown.Header>
            <Dropdown.Item
              eventKey="1"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setFilteredQuantity("1");
                trackQuoteFilter("Quantity", "Under Count");
              }}
              active={filterQuantity === "1"}
            >
              Under Count
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setFilteredQuantity("2");
                trackQuoteFilter("Quantity", "Exact Count");
              }}
              active={filterQuantity === "2"}
            >
              Exact Count
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setFilteredQuantity("3");
                trackQuoteFilter("Quantity", "Over By 1 Piece");
              }}
              active={filterQuantity === "3"}
            >
              Over By 1 Piece
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="4"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setFilteredQuantity("4");
                trackQuoteFilter("Quantity", "Over Count");
              }}
              active={filterQuantity === "4"}
            >
              Over Count
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown as={ButtonGroup} className="btn-group-xs mr-2">
          <Dropdown.Toggle id="dropdown-date" className="btn-tertiary">
            <SvgIcon
              name={filterAvailability === "" ? "filterOutline" : "filterSolid"}
              attributes={{
                width: "12px",
                height: "12px",
                fill: IconColors.WHITE,
              }}
            />{" "}
            Ready Week
          </Dropdown.Toggle>
          <Dropdown.Menu className="btn btn-group-xs">
            <Dropdown.Header>Show Only</Dropdown.Header>
            <Dropdown.Item
              eventKey="1"
              as="button"
              className="btn btn-tertiary"
              disabled={false}
              onSelect={() => {
                setFilteredAvailability("1");
                trackQuoteFilter("Availability Date", "On Time");
              }}
              active={filterAvailability === "1"}
            >
              On Time
            </Dropdown.Item>
            <Dropdown.Header>Past Requested Date</Dropdown.Header>
            <Dropdown.Item
              eventKey="2"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setFilteredAvailability("2");
                trackQuoteFilter("Availability Date", "Up to 5 days Past");
              }}
              active={filterAvailability === "2"}
            >
              Up to 5 days Past
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setFilteredAvailability("3");
                trackQuoteFilter("Availability Date", "Up to 10 days Past");
              }}
              active={filterAvailability === "3"}
            >
              Up to 10 days Past
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="4"
              as="button"
              className="btn btn-outline-tertiary"
              disabled={false}
              onSelect={() => {
                setFilteredAvailability("4");
                trackQuoteFilter("Availability Date", "Up to 15 days Past");
              }}
              active={filterAvailability === "4"}
            >
              Up to 15 days Past
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {"    "}
        <QQLink
          onClick={() => [
            setSelectedPlant(""),
            setFilteredAvailability(""),
            setFilteredQuantity(""),
          ]}
          hidden={selectedPlant === "" && filterAvailability === "" && filterQuantity === ""}
          className={clsx("text-primary")}
        >
          <SvgIcon
            name="clear"
            attributes={{
              width: "12px",
              height: "12px",
              fill: IconColors.PRIMARY,
            }}
          />
          {"    "}
          Clear All Filters
        </QQLink>
      </div>
      {itemIds.map((id) => (
        <React.Fragment key={id}>
          <MillOptionsSection
            item={items[id]}
            iconColor={iconColor}
            itemPlants={itemPlants}
            plantOptions={plantOptions}
            showDetail={showDetail}
            requestDate={requestDate}
            selectedOptionIds={itemsSelected}
            selectedPlants={selectedPlant}
            filterAvailability={filterAvailability}
            filterQuantity={filterQuantity}
            onSelectionChange={onSelectionChange}
            onCutListClick={onCutListClick}
            shipTo={shipTo}
            shippingMethod={shippingMethod}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
